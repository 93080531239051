<template>
	<div class="page">

		<div class="cbox">


			<el-form :model="firstRow" ref="ruleForm" label-width="80px"
				style="max-height: 450px;overflow-y: auto;min-height: 300px;">
				<el-row v-for="(item,idx) in DataList" :key="idx">
					<el-col :span="6">
						<el-form-item label="参数名称" prop="role_name">
							<el-input v-model.trim="item.remark" style="width: 100%" />
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="参数代码" prop="role_name">
							<el-input v-model.trim="item.ktype" style="width: 100%" />
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="参数值" prop="role_name">
							<el-input v-model.trim="item.kvalue" style="width: 100%" />
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item>

							<el-upload style="display: inline-block; margin-right: 20px;" action=""
								:http-request="uploadOss" :on-success="uploadFiles" :show-file-list="false"
								name="image">
								<el-button title="传图" size="small" type="primary" icon="el-icon-picture-outline-round"
									@click="setRowIndx(idx)" circle=""></el-button>
							</el-upload>


							<el-button size="small" type="primary" icon="el-icon-s-claim" @click="saveData(item)">保存
							</el-button>
						</el-form-item>
					</el-col>
				</el-row>

			</el-form>
		</div>

	</div>
</template>

<script>
	export default {
		name: 'stuDept',
		props: ["pmid"],
		data() {
			return {
				dialogShow: true,
				DataList: [],
				firstRow: {
					id: 0,
					pmid: 0,
					ktype: "",
					kvalue: "",
					remark: ""

				},
				rowidx: -1


			}


		},


		mounted() {
			this.getList()
		},
		methods: {
			closeDialog() {
				this.$parent.dialogPramShow = false
			},
			getList() {
				this.$http.post("/api/sys_platform_config_list", {
					pagesize: 50,
					sch_id: this.$store.state.pmid
				}).then(res => {
					this.DataList = res.data.data
				})
			},

			addNew() {
				this.DataList.push({
					...(this.firstRow)
				})
			},
			setRowIndx(idx) {
				this.rowidx = idx
			},
			uploadFiles(e) {

				this.DataList[this.rowidx].kvalue = e.src
			},
			saveData(item) {
				item.pm_id = this.$store.state.pmid;
				this.$http.post("/api/sys_platform_config_edit", item).then(ok => {
					this.$message.success('保存成功')

					this.getList()
				})
			}
		}
	}
</script>

<style>
</style>
